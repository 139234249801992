import { AxiosError } from 'axios'

class Response<T = undefined> {
  data?: T
  statusCode?: number
  message?: string
  error?: Error

  static fromData<U>(
    data: U,
    statusCode: number,
    message?: string
  ): Response<U> {
    const response = new Response<U>()
    response.data = data
    response.statusCode = statusCode
    response.message = message

    return response
  }

  static fromError<U>(error: AxiosError): Response<U> {
    const response = new Response<U>()
    response.error = error

    if (error.response) {
      response.statusCode = error.response.status
      response.message = error.response.data.message
    } else {
      response.statusCode = 500
      response.message =
        'Ocorreu um erro que não foi possível ser tratado, tente novamente mais tarde.'
    }

    return response
  }
}

export default Response
