import React from 'react'

const Logo: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto' }}
    width={42}
    fill="none"
    viewBox="0 0 412 479"
  >
    <g
      stroke="#E6F0FF"
      strokeMiterlimit="10"
      strokeWidth="14"
      clipPath="url(#clip0)"
    >
      <path d="M252.508 73.535c10.692-25.813-1.566-55.407-27.379-66.1-25.813-10.691-55.407 1.567-66.099 27.38-10.692 25.813 1.566 55.407 27.379 66.099 25.814 10.692 55.407-1.566 66.099-27.379z"></path>
      <path d="M376.74 96.08a13.466 13.466 0 00-16.255-5.168 13.47 13.47 0 00-5.015 3.478 204.11 204.11 0 01-23.45 21.9v0c-33.35 28.59-79.57 43.18-126 43.29-46.4-.11-92.62-14.7-126-43.29a202.507 202.507 0 01-23.48-21.91 13.48 13.48 0 00-21.28 1.7c-84.84 151.26 11.51 260.73 162.16 376.21a19.537 19.537 0 007.89 3c.462.05.928.05 1.39 0a19.6 19.6 0 007.89-3C365.35 357.26 460.46 248.74 376.74 96.08z"></path>
      <path d="M4.59 223.72h124.27a13.424 13.424 0 0111.88 7.16l30.77 58.3a8.995 8.995 0 0012.583 3.433 8.995 8.995 0 003.297-3.493l52.42-101.34a9.001 9.001 0 0115.36-.93l21.14 31a13.448 13.448 0 0011.1 5.87h120.5"></path>
    </g>
    <defs>
      <clipPath id="clip0">
        <path
          fill="#fff"
          d="M0 0H411.35V478.7H0z"
          transform="translate(.1 .08)"
        ></path>
      </clipPath>
    </defs>
  </svg>
)

export default Logo
