import { lazy } from 'react'
import ModuleRoute from 'types/ModuleRoute'

const modules: ModuleRoute[] = [
  {
    id: 'companies',
    title: 'Empresas',
    menu: true,
    icon: 'domain',
    component: lazy(() => import('../modules/companies')),
    permission: 'crud_company',
    path: '/companies'
  },
  {
    id: 'user',
    title: 'Usuários',
    path: '/users',
    menu: true,
    icon: 'group',
    permission: 'crud_users',
    component: lazy(() => import('../modules/users'))
  },
  {
    id: 'collaborators',
    title: 'Colaboradores',
    menu: true,
    icon: 'assignment_ind',
    component: lazy(() => import('../modules/collaborator')),
    permission: 'crud_employee',
    path: '/collaborators'
  }
]

export default modules
