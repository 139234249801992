import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Loader } from '@globalhealthmonitor/components'

import { readCurrentDetails } from 'services/authenticate'
import Role from 'types/Role'

import AuthContext from './Context'
import { Container } from './styles'

const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [role, setRole] = useState<Role>()

  useEffect(() => {
    const getData = async () => {
      const response = await readCurrentDetails()

      if (response.data) {
        setRole(response.data.role)
      } else {
        localStorage.removeItem('@ghm:token')
        history.push('/login')
      }

      setLoading(false)
    }

    getData()
  }, [])

  const propsProvider = {
    isPermission: (permission) =>
      role && role.permissions.some((item) => item === permission),

    onLogout: () => {
      localStorage.removeItem('@ghm:token')
      history.push('/login')
    }
  }

  return (
    <AuthContext.Provider value={propsProvider}>
      <Container>{loading ? <Loader full /> : children}</Container>
    </AuthContext.Provider>
  )
}

export default AuthProvider
