import React, { Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { AuthProvider } from 'provider/AuthProvider'
import Menu from 'containers/Menu'
import modules from './modules'

const AuthRouter: React.FC = () => (
  <AuthProvider>
    <Menu modules={modules} />
    <Suspense fallback={<div />}>
      <Switch>
        <Route path="/" exact>
          <Redirect to="/companies" />
        </Route>
        {modules.map((module) => (
          <Route
            key={module.path}
            path={module.path}
            component={module.component}
            exact={false}
          />
        ))}
        <Redirect to="/404" />
      </Switch>
    </Suspense>
  </AuthProvider>
)

export default AuthRouter
