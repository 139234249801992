import axios from 'axios'

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL })
const axiosNoAuthInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('@ghm:token')

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axiosNoAuthInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('@ghm:subtoken')

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export const apiNoAuth = axiosNoAuthInstance
export default axiosInstance
